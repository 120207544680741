<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <div class="col-12 col-md-6">
                    <b-form-group :label="$t('title')">
                        <ValidationProvider name="label" rules="required" v-slot="{ valid, errors }">
                            <b-input-group class="mb-3" prepend="TR">
                                <b-form-input v-model="form.label">
                                </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"
                                                     class="mb-2"></b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <b-form-group :label="$t('title_en')">
                        <ValidationProvider name="label_en" v-slot="{ valid, errors }">
                            <b-input-group prepend="EN">
                                <b-form-input v-model="form.label_en">
                                </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <ValidationProvider name="field" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('field')">
                            <b-form-input v-model="form.field">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-md-6">
                    <ValidationProvider name="translate" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('translate')">
                            <b-form-select v-model="form.translate" :options="translateOptions"></b-form-select>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-md-6">
                    <ValidationProvider name="order" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('order')">
                            <b-form-input type="number" v-model="form.order">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-md-6">
                    <ValidationProvider name="default" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('default')">
                            <b-form-input v-model="form.default">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>

                <div class="col-12 col-md-6">
                    <ValidationProvider name="roles" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('available_roles')">
                            <role-selectbox :valueType="'name'" :multiple="true" v-model="form.available_roles"
                                            :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-md-6">
                    <ValidationProvider name="roles" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('except_roles')">
                            <role-selectbox :valueType="'name'" :multiple="true" v-model="form.except_roles"
                                            :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-md-6">
                    <ValidationProvider name="align" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('align')">
                            <b-form-input v-model="form.align">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-md-6">
                    <ValidationProvider name="format" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('format')">
                            <b-form-input v-model="form.format">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-md-6">
                    <ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('status')">
                            <b-form-select v-model="form.status" :options="statusOptions"></b-form-select>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </b-row>
        </ValidationObserver>

        <div class="col-12 mt-3 d-flex">
            <b-button @click="createForm" :disabled="formLoading" type="button" variant="primary" class="btn-lg mr-2">
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
//Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
//Service
import ExcelExportCrudItemService from "@/services/ExcelExportCrudItemService";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox.vue";

export default {
    components: {
        StatusSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        excel_export_id: null,
    },
    data() {
        return {
            formLoading: false,
            form: {
                label: null,
                label_en: null,
                field: null,
                order: null,
                translate: null,
                default: null,
                available_roles: [],
                except_roles: [],
                align: null,
                format: null,
                status: null
            },
            statusOptions: [
                {text: this.$t('passive'), value: 0},
                {text: this.$t('active'), value: 1},
            ],
            translateOptions: [
                {text: this.$t('yes'), value: 1},
                {text: this.$t('no'), value: 0},
            ],
        }
    },
    methods: {
        async createForm() {
            const isValid = await this.$refs.formModalValidate.validate()
            if (isValid) {
                let formData = new FormData()
                formData.append('excel_export_id', this.excel_export_id)
                formData.append('label', this.form.label)
                formData.append('label_en', this.form.label_en)
                formData.append('field', this.form.field)
                formData.append('order', this.form.order)
                formData.append('status', this.form.status)
                formData.append('translate', this.form.translate)

                if (this.form.default) {
                    formData.append('default', this.form.default)
                }
                if (this.form.align) {
                    formData.append('align', this.form.align)
                }
                if (this.form.format) {
                    formData.append('format', this.form.format)
                }

                this.form.available_roles.forEach(role => {
                    formData.append('available_roles[]', role);
                });
                this.form.except_roles.forEach(role => {
                    formData.append('except_roles[]', role);
                });

                ExcelExportCrudItemService.storeItems(formData)
                    .then((response) => {
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.$emit("createFormSuccess")
                    })
                    .catch((error) => {
                        this.showErrors(error, this.$refs.formModalValidate)
                    });
            }
        }
    }
}
</script>
