import Api from '@/services/Index';

// PARAMETER ITEMS
const getAllItems = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/excel/export/fields', config);
}

const getItem = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/excel/export/fields/' + id);
}

const storeItems = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/excel/export/fields', config);
}
const updateItems = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/excel/export/fields/' + id, formData);
}

const deleteItem = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/excel/export/fields/' + id);
}

export default {
    getAllItems,
    getItem,
    storeItems,
    updateItems,
    deleteItem
}
