<template>
    <div>
        <b-row>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('title')">
                    <b-input-group prepend="TR" class="mb-2">
                        <div class="label-as-input">{{ isNotNullValue(form.label) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('title_en')">
                    <b-input-group prepend="EN">
                        <div class="label-as-input">{{ isNotNullValue(form.label_en) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('field')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.field) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('translate')">
                    <b-input-group>
                        <div class="label-as-input">{{ getApproveText(form.translate) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('order')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.order) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('default')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.default) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('align')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.align) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('format')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.format) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('status')">
                    <b-input-group>
                        <div class="label-as-input">{{ getStatusText(form.status) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    //Service
    import ExcelExportCrudItemService from "@/services/ExcelExportCrudItemService";

    export default {
        props: {
            formId: {
                type: Number,
            }
        },
        data() {
            return {
                formLoading: false,
                form: {
                    rank: null,
                    data: null,
                    code: null,
                    name: null,
                    name_en: null,
                }
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                ExcelExportCrudItemService.getItem(id)
                                .then((response) => {
                                    this.form = response.data.data;
                                    this.form.status = this.form.status == 1 ? 'a' : 'p';
                                })
                                .catch((error) => {
                                    this.showErrors(error)
                                })
            },
        }
    }
</script>
